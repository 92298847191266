var generic = generic || {};

(function ($) {
  Drupal.behaviors.deeplinkContentBlockv1 = {
    getDeeplinkURL: function () {
      var queryParams = generic.env.parsedQuery();

      return queryParams['deeplink'] || '';
    },
    attach: function (context) {
      var deeplinkURL = this.getDeeplinkURL();

      if (deeplinkURL === '') {
        return;
      }
      var $selectedBlock = $('.js-deeplink-' + deeplinkURL, context);

      if ($selectedBlock.length > 0) {
        $(window).on('load', function () {
          var headerHeight = $('.page-header', context).outerHeight();
          var positionMethod = Number($selectedBlock.data('positionMethod')) || 0;
          var distance = Number($selectedBlock.data('distance')) || 0;
          var customOffset = positionMethod * distance;

          $('html, body').animate({ scrollTop: $selectedBlock.offset().top - headerHeight + customOffset }, 500);
        });
      }
    }
  };
})(jQuery);
